<template>
  <div class="main-container">
    <el-breadcrumb separator="/" class="breadcrumb" style="margin:0.6rem;display: inline-block;">
      <el-breadcrumb-item style="">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item>出库信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-link size="mini" type="primary"  icon="el-icon-back" class="back-btn" @click="goBack">返回上一页</el-link>
    <div class="content-block">
      <am-detail :isBreadcrumb="false" :isMapShow="false" @getForm="getData"></am-detail>
      <div>
        <div class="content-title  separate-line">
          <p>出库信息</p>
        </div>
        <div>
          <el-form ref="form" :model="form" label-width="100px" label-position="left">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="出库单号">
                  <el-input size="small" v-model="form.checkout_no" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="出库时间">
                  <el-input size="small" v-model="form.checkout_time" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="出库操作人">
                  <el-input size="small" v-model="form.checkout_user_name" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="终端用户">
                  <el-radio v-model="form.checkout_obj" @input="changeCheckoutObj" :label="1101">经销商</el-radio>
                  <el-radio v-model="form.checkout_obj" @input="changeCheckoutObj" :label="1102">医院</el-radio>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="经销商" v-if="form.checkout_obj =='1101'">
                  <el-select v-model="form.object" remote :remote-method="getDealerOptions"  filterable placeholder="请选择经销商" size="small">
                    <el-option
                        v-for="item in dealerOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <el-form-item label="医院" v-if="form.checkout_obj == '1102'">
                  <el-select v-model="form.object" remote :remote-method="getHospitalOptions" filterable placeholder="请选择医院"  size="small">
                    <el-option
                        v-for="item in hospitalOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item"
                        :filter-method="getHospitalOptions">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="联系人" >
                  <el-input size="small" v-model="form.object.contact_user" disabled></el-input>
<!--                  <span>{{form.object.contact_user ? form.object.contact_user : ''}}</span>-->
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="联系电话">
                  <el-input size="small" v-model="form.object.contact_phone" disabled></el-input>
<!--                  <span>{{form.object.contact_phone ? form.object.contact_phone : ''}}</span>-->
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
          <div class="footer">
            <el-button type="primary" size="small" @click="save(1)">提交</el-button>
            <el-button size="small" @click="save(0)">暂存</el-button >
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>

import AssetsInfo from "@/views/AssetManagement/components/assetsInfo.vue";
import AmDetail from "@/views/AssetManagement/amDetail.vue";
import {getCheckInfoTemp, saveCheckInfo} from '@/api/assetMgt'
import {hospitals_list, search_customer} from '@/api/search.js'
export default {
  name: 'outStoreEdit', // 填写出库信息
  components: {AssetsInfo,AmDetail},
  data(){
    return{
      options: [
        {label:'经销商', value:1101},
        {label:'医院', value:1102},
      ],
      hospitalOptions: [],
      dealerOptions: [],
      form:{
        instrument_id: this.$route.query.instrument_id,
        checkout_obj:'',
        object_id:'',
        checkout_no:'',
        checkout_time:'',
        checkout_uid:'',
        checkout_user_name:'',
        object: {
          contact_phone: '',
          contact_user: '',
        },
      },
    }
  },
  created() {
    this.getCheckInfo()
  },
  methods:{
    getData(){},
    getCheckInfo(){ // 获取暂存信息
      getCheckInfoTemp({instrument_id:this.$route.query.instrument_id ||'',is_submitted: 0}).then(res=>{
        this.form.checkout_no = res.data.checkout_no
        this.form.checkout_time = res.data.checkout_time
        this.form.checkout_uid = res.data.checkout_uid
        this.form.checkout_user_name = res.data.checkout_user_name
        this.form.checkout_obj = res.data.checkout_obj
        this.form.object_id = res.data.object_id
        this.form.object_name = res.data.object_name
        this.form.object = {
          value: res.data.object_id,
          label: res.data.object_name,
        }
        this.form.object.contact_user = res.data.contact_user
        this.form.object.contact_phone = res.data.contact_phone
        if(this.form.checkout_obj == 1102){
          this.getHospitalOptions(this.form.object_name)
        }else{
          this.getDealerOptions(this.form.object_name)
        }
      })
    },
    changeCheckoutObj(val){
      if(val){
        this.form.object_id = ''
      }
    },
    getHospitalOptions(e){
      this.hospitalOptions = hospitals_list({ hospital_name: e })
    },
    getDealerOptions(e){
      this.dealerOptions = search_customer({ customer_name: e })
    },
    save(type){
      this.form.object_id = this.form.object.value
      if(!this.form.object_id){
        return this.$message.error('请填写具体的终端用户信息！！')
      }
      const loading = this.$loading({
        lock: true,
        text: type ? '提交中...' :'保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.form.is_submitted = type
      saveCheckInfo(this.form).then(res => {
        this.$message.success( type ? '提交成功' :'保存成功')
        this.$router.push('/assetMgt/list')
        loading.close()
      }).catch(err=>{
        loading.close()
      })
    },
    goBack(){
      this.$router.push('/assetMgt/list')
    },
    handleRemove(file, fileList) {
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
.footer{
  text-align: center;
}
//特殊详情处理
.zone1{
  padding: 0;
}
::v-deep .el-tabs__header{
  padding-left: 10px;
}
</style>